import { useEffect, useState } from "react";
import { useCons } from "../context/ConsContext";
import "./index.css";
import InputForm from "./InputForm";
import InputPhone from "./InputPhone";
import axios from "axios";
import { useNavigate } from "react-router-dom";

/* Options import */

import { horarioOptions, usadoOptions } from "./Options";

export default function PopUp() {
  const urlHook = "https://hook.us1.make.com/ibb8r223txg01vojw39gffmp2bi9pbtb";

  const navigate = useNavigate();
  const { popActive, setPopActive, modelos, modelsOptions } = useCons();
  const queryParams = new URLSearchParams(window.location.search);

  const valores = {
    1: {
      origen: "GG-Ads",
      fuente: "Mango",
    },
    2: {
      origen: "FB-Ads",
      fuente: "Mango",
    },
    default: {
      origen: "FB-Ads",
      fuente: "Mango",
    },
  };

  const [data, setData] = useState({
    modelid: "",
    model: "",
    carmodel: "",
    name: "",
    phoneVeri: "",
    telefonoValidado: false,
    usado: "No",
    email: "",
    schedule: "",
    city: "",
    origen:
      valores[queryParams.get("origen")]?.origen || valores["default"].origen,
    fuente:
      valores[queryParams.get("origen")]?.fuente || valores["default"].fuente,
    medio: queryParams.get("medio"),
    debug: queryParams.get("debug") || 0,
    landing: window.location.origin + window.location.pathname,
    eventid: Date.now(),
    pilot_suborigen_id: "EVGCVCCJQGGMIJ41G",
  });

  /* Model ID select */

  const modelID = (modelcar) => {
    return modelcar.filter(({ dataButton }) => {
      return dataButton === data.carmodel;
    });
  };

  useEffect(() => {
    const modeloID = modelID(modelos);
    console.log(modeloID);
    modelID !== undefined
      ? setData({
          ...data,
          modelid: modeloID[0]?.id,
          model: modeloID[0]?.modelo,
        })
      : null;
  }, [data.carmodel]);

  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const checkDataStep = () => {
    if (step === 1) {
      if (!data.name) {
        setError("Verificar Nombre");
        return false;
      }
      setError("");
      if (!data.model) {
        setError("Elegír plan");
        return false;
      }
      setError("");
      return true;
    }
    if (step === 2) {
      if (!data.usado) {
        setError("Elige una opción");
        return false;
      }
      setError("");
      return true;
    }
    if (step === 3) {
      if (!data.phoneVeri) {
        setError("Verificar Teléfono");
        return false;
      }
      setError("");
      if (!data.telefonoValidado) {
        return false;
      }
      setError("");

      if (!data.schedule) {
        setError("Elegír un horario");
        return false;
      }
      setError("");
      return true;
    }
    if (step === 4) {
      if (!data.email) {
        setError("Verificar Email");
        return false;
      }
      if (!data.city) {
        setError("Verificar Ciudad");
        return false;
      }
      setError("");
      return true;
    }
  };

  const stepChange = (e, to) => {
    e.preventDefault();
    if (!checkDataStep()) {
      return false;
    }
    setStep(to);
  };

  const handleChangeInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setStep(1);
  }, [popActive]);

  useEffect(() => {
    setData({ ...data, carmodel: popActive.clickModel });
    // eslint-disable-next-line
  }, [popActive.clickModel]);

  /* DataLayer Steps */

  useEffect(() => {
    try {
      dataLayer.push({
        event: "StepForm",
        step: `S${step}`,
      });
    } catch (error) {
      console.log(error);
    }
  }, [step]);

  /* Form Submit */
  const [formLoading, setFormLoading] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkDataStep()) {
      return false;
    }
    setFormLoading(true);
    setError("");

    await axios
      .post(urlHook, data, {
        Accept: "application/json",
        "Content-Type": "application/json",
      })
      .then(function (response) {
        //Success ----------------------------------------------------
        console.log("Form Success", response);

        try {
          // eslint-disable-next-line no-undef
          gtag("event", "Goal", {
            event_category: `Click Button Form`,
            event_label: "Form",
          });
        } catch (errorC) {
          console.log(errorC);
        }
        try {
          // eslint-disable-next-line no-undef
          fbq("track", "Lead");
        } catch (errorC) {
          console.log(errorC);
        }
        setFormSuccess(true);
        setPopActive(false);
        navigate("/send");
      })
      //End Succes ----------------------------------------------------
      .catch(function (error) {
        //Error ----------------------------------------------------
        setFormLoading(false);
        try {
          // eslint-disable-next-line no-undef
          gtag("event", "Forms", {
            event_category: "Form Error",
            event_label: error,
          });
        } catch (errorC) {
          console.log(errorC);
        }
        console.log(error);
        console.log("error un problema");
        setError("Hubo un problema al enviar los datos");
      });
    //End Error ----------------------------------------------------
  };

  return (
    <div
      className={popActive.active ? "containerPop activePop" : "containerPop"}
    >
      <div className="overlayPop" onClick={() => setPopActive(false)} />
      <div className="popUp">
        <div className="close" onClick={() => setPopActive(false)}>
          <span></span>
          <span></span>
        </div>
        <div className="stepView">
          <div className="step active">
            <span />
            <p>Paso 1</p>
          </div>
          <div className={step >= 2 ? "step active" : "step"}>
            <span />
            <p>Paso 2</p>
          </div>
          <div className={step >= 3 ? "step active" : "step"}>
            <span />
            <p>Paso 3</p>
          </div>
          <div className={step >= 4 ? "step active" : "step"}>
            <span />
            <p>Paso 4</p>
          </div>
        </div>
        <h2>
          Cotiza tu{" "}
          {!popActive.clickModel ? (
            "Volkswagen"
          ) : (
            <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
              {popActive.clickModel.toLowerCase()}
            </span>
          )}
        </h2>
        {step === 1 && <p>Completá tus datos y accedé a tu cotización</p>}
        {step === 3 && <p>Confirmá tu teléfono y hora de contacto</p>}
        {step === 4 && <p>Último paso para acceder a toda la info</p>}
        <form
          onSubmit={handleSubmit}
          className={"step" + step + " form"}
          noValidate
        >
          {step === 1 && (
            <>
              <InputForm
                type="text"
                name="name"
                placeholder="Ingresá tu Nombre"
                value={data.name}
                handleChangeInput={handleChangeInput}
                error="Debe introducir un nombre"
                required={true}
              />

              {!popActive.clickModel && (
                <InputForm
                  type="select"
                  name="carmodel"
                  placeholder="Elegí el Modelo que te Interesa"
                  handleChangeInput={handleChangeInput}
                  value={data.modelcar}
                  options={modelsOptions}
                  error="Selecciona un plan"
                  required={true}
                />
              )}

              <div className="navStep">
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 2)}
                >
                  Siguiente
                </button>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <InputForm
                type="radio"
                name="usado"
                value={data.usado}
                options={usadoOptions}
                placeholder="¿Entregarías un auto usado como parte de pago? "
                handleChangeInput={handleChangeInput}
                error="Debe seleccionar una opción"
                required={false}
              />
              <div className="navStep">
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 1)}
                >
                  Atrás
                </button>
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 3)}
                >
                  Siguiente
                </button>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <InputPhone data={data} setData={setData} />

              <InputForm
                type="select"
                name="schedule"
                placeholder="Elegí una Hora de Contacto"
                handleChangeInput={handleChangeInput}
                value={data.schedule}
                options={horarioOptions}
                error="Debe seleccionar un horario"
                required={true}
              />

              <div className="navStep">
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 2)}
                >
                  Atrás
                </button>
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 4)}
                >
                  Siguiente
                </button>
              </div>
            </>
          )}

          {step === 4 && (
            <>
              <InputForm
                type="email"
                name="email"
                value={data.email}
                placeholder="Confirmá tu email y recibir un detalle de la propuesta"
                handleChangeInput={handleChangeInput}
                error="Debe introducir un email válido"
                required={true}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              />
              <InputForm
                type="text"
                name="city"
                value={data.city}
                placeholder="Ingresá tu ciudad o localidad."
                handleChangeInput={handleChangeInput}
                error="Debe introducir una ciudad"
                required={true}
              />
              <div className="navStep">
                <button
                  className="buttonForm btnnext"
                  onClick={(e) => stepChange(e, 3)}
                >
                  Atrás
                </button>
                <button
                  className={
                    formLoading
                      ? "buttonForm loadBoton"
                      : formSuccess
                      ? "buttonForm formSuccess"
                      : "buttonForm"
                  }
                  type="submit"
                >
                  {formLoading
                    ? "Enviando..."
                    : formSuccess
                    ? "Datos Enviados"
                    : "Enviar Consulta"}
                </button>
              </div>
            </>
          )}
        </form>
        {error && (
          <span className="error">
            <svg
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5474 14.3026L13.8774 1.58262C13.5673 1.09744 13.14 0.698147 12.6349 0.421562C12.1298 0.144976 11.5632 0 10.9874 0C10.4116 0 9.84499 0.144976 9.33992 0.421562C8.83485 0.698147 8.40755 1.09744 8.0974 1.58262L0.427405 14.3026C0.156458 14.7543 0.00919365 15.2693 0.00041668 15.7959C-0.00836029 16.3225 0.12166 16.8422 0.377405 17.3026C0.673084 17.8209 1.10106 18.2514 1.61761 18.55C2.13415 18.8487 2.72073 19.0049 3.3174 19.0026H18.6574C19.2502 19.0089 19.8341 18.8588 20.3503 18.5674C20.8665 18.2759 21.2966 17.8534 21.5974 17.3426C21.8607 16.8774 21.9948 16.3503 21.986 15.8158C21.9773 15.2814 21.8258 14.7589 21.5474 14.3026V14.3026ZM19.8474 16.3526C19.7272 16.5592 19.5528 16.729 19.343 16.8436C19.1333 16.9581 18.8962 17.0131 18.6574 17.0026H3.3174C3.07863 17.0131 2.84152 16.9581 2.63176 16.8436C2.42201 16.729 2.24759 16.5592 2.1274 16.3526C2.03964 16.2006 1.99343 16.0282 1.99343 15.8526C1.99343 15.6771 2.03964 15.5046 2.1274 15.3526L9.8074 2.62262C9.94541 2.44033 10.1238 2.29249 10.3285 2.1907C10.5332 2.08891 10.7588 2.03593 10.9874 2.03593C11.216 2.03593 11.4416 2.08891 11.6463 2.1907C11.851 2.29249 12.0294 2.44033 12.1674 2.62262L19.8374 15.3426C19.9286 15.4949 19.9775 15.6688 19.9793 15.8463C19.9811 16.0238 19.9355 16.1985 19.8474 16.3526V16.3526Z"
                fill="#001e50"
              />
              <path
                d="M10.9873 15.0026C11.5396 15.0026 11.9873 14.5548 11.9873 14.0026C11.9873 13.4503 11.5396 13.0026 10.9873 13.0026C10.435 13.0026 9.9873 13.4503 9.9873 14.0026C9.9873 14.5548 10.435 15.0026 10.9873 15.0026Z"
                fill="#001e50"
              />
              <path
                d="M10.9873 6.00256C10.7221 6.00256 10.4677 6.10792 10.2802 6.29546C10.0927 6.48299 9.9873 6.73735 9.9873 7.00256V11.0026C9.9873 11.2678 10.0927 11.5221 10.2802 11.7097C10.4677 11.8972 10.7221 12.0026 10.9873 12.0026C11.2525 12.0026 11.5069 11.8972 11.6944 11.7097C11.8819 11.5221 11.9873 11.2678 11.9873 11.0026V7.00256C11.9873 6.73735 11.8819 6.48299 11.6944 6.29546C11.5069 6.10792 11.2525 6.00256 10.9873 6.00256Z"
                fill="#001e50"
              />
            </svg>
            {error}
          </span>
        )}
        {/* <pre style={{ width: "100%", overflowWrap: "break-word" }}>
          <code style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(data)}</code>
        </pre> */}
      </div>
    </div>
  );
}
