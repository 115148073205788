import React from "react";
import "./index.css";

import logo from "../assets/logo.svg";
import { useCons } from "../context/ConsContext";

const SubFooter = ({ dataButton }) => {
  const { buttonData } = useCons();

  return (
    <>
      <div className="section noPadTop">
        <div className="colImg flex flexRow flexWrap">
          <div className="imgBck"></div>
          <div className="textFooter">
            <h1>
              Plan Llave por Llave <br />
              <strong>Tomamos tu Usado</strong>
            </h1>
            <button
              className="btn whitebtn"
              onClick={() => buttonData(true, dataButton)}
            >
              Consultar ahora
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-lg flex flexRow flexWrap space">
          <p>
            <img src={logo} alt="logo" />
            Volkswagen Plan de Ahorro -
            <a
              href="https://tengouncero.com.ar/tyc"
              target="_blank"
              style={{ display: "block" }}
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>
          </p>
          <p>
            Mango&amp;Cía
            <a
              href="https://mangoycia.com/?utm_source=partners&amp;utm_medium=vwm&amp;utm_campaign=partners"
              target="_blank"
            >
              Agencia de Performance
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
